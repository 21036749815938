import React from 'react'

function PageLoadingError({ status, errorMessage, className }) {
  return(
    <div className={className}>
      { errorMessage && (Array.isArray(errorMessage) ? errorMessage.join("\n") : errorMessage) }
    </div>
  )
}

export default PageLoadingError
